import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'

import Button from 'components/button'
import PageHead from 'components/head/PageHead'
import Heading from 'components/heading/Heading'
import PageWrapper from 'components/layout/page-wrapper'
import QuoteSection from 'components/pages/shared/quote-section'
import SecurityData from 'data/pages/Contents/security.json'
import { useRellax } from 'js/utils/hooks'
import buildImageObject from 'js/utils/buildImgObject'

import 'static/security/scss/styles.scss'

const page = 'security'
const data = SecurityData

const codeSnippet = `-----BEGIN PGP SIGNED MESSAGE-----
Hash: SHA256

Verify:
- - - https://keybase.io/exodusmovement/pgp_keys.asc?fingerprint=ef2ccd36c16a8b90da1932e0926dc59fbb75b328
- - - https://keybase.io/exodusmovement

NOTE:

Future releases may also be signed with the following key instead:

- - - https://keybase.io/jprichardson/pgp_keys.asc?fingerprint=12408650e2192febe4e7024c9d959455325b781a
- - - https://keybase.io/jprichardson
`

const images = {
  headerImage: {
    aspectRatio: 1.54,
    alt: 'Security in design & development',
    path: '/security/img/',
    fileType: 'png',
    files: [
      {
        fileName: 'header-bg-ssize',
        width: 640,
      },
      {
        fileName: 'header-bg-lsize',
        width: 2000,
      },
    ],
  },
  featuredSection: {
    items: [
      {
        aspectRatio: 1,
        path: '/security/img/',
        fileType: 'svg',
        files: [
          {
            fileName: 'feature__00',
            width: 140,
          },
        ],
      },
      {
        aspectRatio: 1,
        path: '/security/img/',
        fileType: 'svg',
        files: [
          {
            fileName: 'feature__01',
            width: 140,
          },
        ],
      },
      {
        aspectRatio: 1,
        path: '/security/img/',
        fileType: 'svg',
        files: [
          {
            fileName: 'feature__02',
            width: 140,
          },
        ],
      },
      {
        aspectRatio: 1,
        path: '/security/img/',
        fileType: 'svg',
        files: [
          {
            fileName: 'feature__03',
            width: 140,
          },
        ],
      },
    ],
  },
}

const BackgroundImage = ({ image, speed }) => (
  <div
    className={classNames('x__background-container', { 'security-rellax': speed })}
    data-rellax-speed={speed}
  >
    <div className="x__background">
      <Img fluid={buildImageObject(image)} alt={image.alt} durationFadeIn={2000} />
    </div>
  </div>
)

const ChromeImage = ({ index, containerClassName, speed }) => (
  <span
    className={classNames(`${containerClassName}__chrome ${containerClassName}__chrome--${index}`, {
      'security-rellax': speed,
    })}
    data-rellax-speed={speed}
    role="presentation"
  />
)

const HeaderSection = ({ data }) => (
  <header className="x-header">
    <div className="x__content">
      <div className="x-header__container">
        <div className="x-header__container-headings security-rellax" data-rellax-speed="3">
          <Heading page={page} />
        </div>
      </div>

      <ChromeImage index={1} containerClassName="x-header" speed={-1} />

      <div className="x-header__container x-header__container-wrapper">
        <p className="x-header__suptitle" dangerouslySetInnerHTML={{ __html: data.suptitle }} />

        <p className="x-header__description">
          <span
            className="x-header__description--color"
            dangerouslySetInnerHTML={{ __html: data.description }}
          />
          <span
            className="x-header__description--white"
            dangerouslySetInnerHTML={{ __html: data.description }}
          />
        </p>
      </div>
    </div>

    <BackgroundImage image={images.headerImage} speed={-4} />
  </header>
)

const FeaturesSection = ({ data }) => {
  if (!data.items) return null

  const sectionImages = images.featuredSection.items
  const items = data.items.map((obj, i) => ({ ...obj, ...sectionImages[i] }))

  return (
    <section className="x-features">
      <div className="x-features__container">
        {items.map((item, i) => (
          <div key={i} className="x-features__item">
            <Img
              className={`x-features__item__img x-features__item__img--${i + 1}`}
              fluid={buildImageObject(item)}
              alt={item.heading}
              durationFadeIn={2000}
            />
            <h3 className="x-header__subheading">{item.heading}</h3>
            <p className="x-header__description">{item.description}</p>
          </div>
        ))}
      </div>
    </section>
  )
}

const BountySection = ({ data }) => (
  <section className="x-bounty">
    <div className="x__content">
      <div>
        <div className="x-header__container">
          <Heading page={page} section="bounty" type="h2" />
          {!!data.description &&
            data.description.map((paragraph, i) => (
              <p
                key={i}
                className="x-header__description"
                dangerouslySetInnerHTML={{ __html: paragraph }}
              />
            ))}
        </div>
      </div>

      <div>
        <pre className="x-bounty__code-snippet">{codeSnippet}</pre>
      </div>
    </div>
  </section>
)

function SecurityPage({ location }) {
  useRellax({ className: 'security-rellax', center: false })

  const images = useStaticQuery(graphql`
    query {
      quoteBg: file(relativePath: { regex: "/^security\/img\/quote-background\\.jpg$/" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, width: 1170, placeholder: BLURRED)
        }
      }
      quoteChromes: file(relativePath: { regex: "/^security\/img\/quote-chromes\\.png$/" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, width: 1072, placeholder: BLURRED)
        }
      }
    }
  `)
  const sectionImages = {
    quote: {
      background: getImage(images?.quoteBg),
      chromes: getImage(images?.quoteChromes),
    },
  }

  return (
    <PageWrapper
      className="x-page-security"
      headerProps={{
        isBannerHidden: true,
        location,
        trackerPage: 'security',
      }}
      footerProps={{
        enableSubscribeNotice: false,
      }}
    >
      <HeaderSection data={data.header} />
      <FeaturesSection data={data.features[0]} />
      {!!data.quote && (
        <QuoteSection data={{ ...data.quote, ...sectionImages.quote }}>
          <Button
            copy={data.quote.cta.copy}
            to={data.quote.cta.path}
            size="largest"
            type="tertiary"
          />
        </QuoteSection>
      )}
      <BountySection data={data.bounty} />
    </PageWrapper>
  )
}

export default SecurityPage

// <head> component:
export function Head() {
  return <PageHead page={page} />
}
